@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #FCFCF8;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper .swiper-slide {
  height: auto;
}

.swiper-slide img {
  display: block;
  object-fit: fill;
}

.mainBanner .swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  width: 48px !important;
  height: 3px !important;
  background-color: #BABAAF !important;
  border-radius: 0 !important;
}

.swiper-button-prev {
  width: 32px !important;
  height: 32px !important;
  background-image: url(https://static.greenparenting.au/images/icon/circle-right-arrow.png);
  background-size: cover;
  background-position: center;
}

.swiper-button-next {
  width: 32px !important;
  height: 32px !important;
  background-image: url(https://static.greenparenting.au/images/icon/circle-left-arrow.png);
  background-size: cover;
  background-position: center;
}

.swiper-button-prev:after, .swiper-button-next:after {
  content: "" !important;
}

@media (max-width: 760px) {
  .swiper-pagination-bullet {
    height: 2px !important;
  }
}

.swiper-pagination-bullet-active {
  background-color: #A6CAA6 !important;
}

.top-banner-swiper {
  width: 100%;
  height: 50px;
}

.mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 28vh;
  height: auto;
  background-color: #FCFCF8;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.top-banner-mega-menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  text-align: left;
  width: 100%;
  min-height: 28vh;
  height: auto;
  background-color: #FCFCF8;
  z-index: 2;
  padding: 0.8rem, 1.5rem, 2rem, 1.5rem;
}

.mega-menu-height {
  height: calc(100% - 162px);
}

/* #hoverable Class Styles */
.hoverable {
  position: static;
}

.hoverable:hover .mega-menu, .hoverable:hover .top-banner-mega-menu {
  display: block;
}

.sub-category-height {
  height: calc(100% - 50px);
}

.video-banner {
  height: calc(100vh - 73px);
}

/* flex shrink */
.our-values {
  height: 563px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.value {
  flex: 1;
  overflow: hidden;
  transition: 1s;
}

.value:hover {
  flex: 1 1 40%;
}

.value > img, .value > a > img {
  height: 563px;
  object-fit: cover;
  transition: 1s;
}

.value:hover > img {
  height: 563px;
}

.rotate-vertical {
  position: absolute;
  z-index: 5;
  top: 30px;
  right: 0;
  writing-mode: vertical-lr;
}

@media (max-width: 760px) {
  .value > a > img, .value:hover > a > img {
    height: 335px;
  }

  .rotate-vertical {
    top: 14px;
  }
}

/* modal */
.react-confirm-alert-overlay {
  background-color: rgba(89, 89, 89, 0.4) !important;
}

@media (max-width: 767px) {
  .react-confirm-alert {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .react-confirm-alert-overlay {
    background: rgba(40, 40, 41, 0.5) !important;
  }

  .react-confirm-alert-body {
    width: 100% !important;
    border-radius: 20px 20px 0 0 !important;
  }
}

.cart-product-height {
  height: calc(100% - 156px);
}

@media (max-width: 760px) {
  .cart-product-height {
    height: calc(100% - 120px);
  }
}

.size-guide-height {
  height: calc(100% - 58px);
}

/* scrollbar */
/* width, height */
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  background-clip: content-box;
  border: 5px solid transparent;
}

/* hide default select arrow */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* mini product */
.mini-product:hover > img:nth-last-of-type(2) {
  opacity: 0;
}

.mini-product:hover > img:last-child {
  opacity: 100;
}


.select-voucher {
  width: 100%;
  height: 32px;
  max-height: 32px;
  /* box-shadow: 0px 0px 0px 2px white inset; */
  position: relative;
}

.select-voucher::before {
  content: "";
  position: absolute;
  top: 80%;
  left: 25%;
  width: 20%;
  height: 2px;
  background-color: white;
  transform-origin: right;
  transform: rotate(45deg) translateY(-50%);
}

.select-voucher::after {
  content: "";
  position: absolute;
  top: 80%;
  right: 23%;
  width: 30%;
  height: 2px;
  background-color: white;
  transform-origin: left;
  transform: rotate(-45deg) translateY(-50%);
}

.react-datepicker-wrapper {
  width: 100%;
}
